.app {
  align-items: center;
  background-color: #9025e9;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  text-align: center;
}
